import { useTranslations } from 'next-intl';
import Image from 'next/image';

import type { TGiftingListData } from '@/common';
import { Button } from '@/components/atoms';
import Link from 'next/link';

function Gifting() {
  const t = useTranslations();

  const giftingListData = t.raw('giftingListData') as TGiftingListData[];

  return (
    <div className="w-full bg-bg-body">
      <div className="container py-20 pt-10 sm:mb-10 sm:py-10">
        <h2 className="h1 mb-6 text-center sm:mb-10">Gifting at Every Scale</h2>
        <div className="flex flex-col items-center justify-center gap-14 sm:flex-row sm:gap-10">
          {giftingListData.map((gifting) => (
            <div className="flex w-full max-w-full flex-col sm:max-w-[460px]" key={gifting.title}>
              <div className="mb-6 h-[12.5rem] w-full overflow-hidden rounded-[1.5rem]">
                <Image
                  src={gifting.image}
                  alt={gifting.title}
                  width={460}
                  height={200}
                  className="w-full object-cover duration-500 ease-out hover:scale-[115%]"
                />
              </div>
              <div className="text-center">
                <h3 className="subtitle1-bold">{gifting.title}</h3>
                <p className="body1-regular mb-4 text-fg-weak">{gifting.description}</p>
                <Link href={gifting.link} passHref legacyBehavior>
                  <Button as="a" size="lg" aria-label={`Learn more about ${gifting.title}`}>
                    {gifting.linkText}
                  </Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { Gifting };
